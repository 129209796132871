<template>
    <yd-layout>
        <yd-navbar slot="navbar" title="购花业务展业" class="white" bgcolor="#F5F6F7" fontsize=".36rem" height=".88rem">
            <div @click="handleBack" slot="left">
                <i class="iconfont iconfanhuiback"></i>
            </div>
        </yd-navbar>
        <div style="text-align:center">
            <div class="face-box">
                <img  src="../assets/logo@2x.png" srcset="../assets/logo@2x.png 2x,../assets/logo@3x.png 3x" alt="">
            </div>
        </div>
        
        <yd-cell-group style="margin:0 .58rem;margin-top:.2rem">
            <yd-cell-item class="icon-title">
                <i slot="icon" class="iconfont iconshoujihao"></i>
                <span slot="left">手机号</span>
            </yd-cell-item>
            <div class="cell-item">
                <yd-input v-model.trim="phone" regex="mobile" placeholder="请输入手机号码"></yd-input>
            </div>
            <yd-cell-item class="icon-title">
                <i slot="icon" class="iconfont iconyanzhengma"></i>
                <span slot="left">验证码</span>
            </yd-cell-item>
            <div class="cell-item verifycode">
                <yd-input v-model.trim="code" regex="^\d{6}$" placeholder="请输入验证码"></yd-input>
                <span @click="handleSend" class="btn" :class="{isBtn:acitve}">{{count>0?count:btnText}}</span>
            </div>
        </yd-cell-group>
    
        <div class="foot">
            <yd-button size="large" type="primary" @click.native="handleClick" class="f-btn" shape="circle">登录</yd-button>
        </div>
  
    </yd-layout>
      
</template>
<script>
    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                showUserName: '**',
                btnText:'验证码',
                count:0,
                phone:'',
                phoneReg :new RegExp(/^1[3456789][\d]{9}$/),
                code:'',
                acitve:true
            }
        },
        computed: {
            // ...mapGetters('jiangsu',['deviceInfo','userInfo'])
        },
        mounted () {
            // const {userInfo:{userRealName}} = this;
            // var res = userRealName.slice(0,userRealName.length-1);
            // this.showUserName = userRealName.replace(res,"*");
        },
        methods: {
            handleClick() {
                const {code,phone,phoneReg,$store} = this;
                if(!phoneReg.test(phone)){
                    this.$dialog.toast({
                        mes: '手机号格式错误',
                        timeout: 1500,
                        icon: 'error'
                    });
                    return 
                }
                if(!code){
                    this.$dialog.toast({
                        mes: '请输入验证码',
                        timeout: 1500,
                        icon: 'error'
                    });
                    return 
                }
                this.$dialog.loading.open('登录中...');
                $store.dispatch('login',{phone,code})
                .then(({status,msg})=>{
                    this.$dialog.loading.close();
                    if(status === 200){
                        this.$router.push('/')
                    }else{
                        this.$dialog.alert({mes: msg || '登录失败！'});
                    }
                })
            },
            countDown(){
                var num = 60;
                var timer = setInterval(()=>{
                    if(num<=0){
                        clearInterval(timer);
                        return;
                    }
                    num--;
                    this.btnText = '重新获取';
                    this.count = num;
                },1000);
            },
            handleSend(){
                const {count,phone,phoneReg,$store} = this;
                if(count>0) return;
                if(!phoneReg.test(phone)){
                    this.$dialog.toast({
                        mes: '手机号格式错误',
                        timeout: 1500,
                        icon: 'error'
                    });
                    return 
                }
                this.$dialog.loading.open('短信发送中...');
                $store.dispatch('sendSmsCode',{phone,smsKey:'loginCode'})
                .then(({status,msg})=>{
                    this.$dialog.loading.close();
                    if(status === 200){
                        this.countDown();
                        this.$dialog.toast({
                            mes: '发送成功，请注意查收',
                            timeout: 1500,
                            icon: 'success'
                        });
                    }else{
                        this.$dialog.alert({mes: msg});
                    }
                })
            },
            //返回
            handleBack(){
                // const {deviceInfo} = this;
                // this.$router.push({path:'/',query:deviceInfo});
            }
        },
    }
</script>
<style lang="scss" scoped>
    // .yd-tabbar{
    //   border-top: 0;
    //   /deep/ .yd-tabbar-txt{
    //     color: #fff;
    //     font-size: .3rem;
    //   }
    // }
    
    .face-box{
        margin-top: 1rem;
    }
    .icon-title{
        margin-top: .2rem;
        .iconfont{
            color: var(--color-primary);
            font-size: .44rem;
        }
    }
    /deep/ .yd-cell-item:not(:last-child):after{
        height:0;
    }
    /deep/ .yd-cell:after{
        height:0;
    }
    .cell-item{
        padding:0 .24rem;
        position: relative;
        .yd-input{
            height: 1rem;
            background: rgba(241, 246, 245, .5);
            border-radius: 1rem;
            padding: 0 .3rem 0 .7rem;
            /deep/ input{
                font-size: .32rem;
                font-weight: normal;
            }
            /deep/ input:-ms-input-placeholder{
                color: #C5CBCA;
            }
            /deep/ input:-webkit-input-placeholder{
                color: #C5CBCA;
            }
        }
    }
    .verifycode{
        .yd-input{
            padding-right:2.18rem;
        }
        .btn{
            position: absolute;
            top: 0;
            bottom: 0;
            right: .68rem;
            margin: auto;
            z-index: 99;
            font-size: .28rem;
            display: inline-block;
            border-radius: .64rem;
            border: 1px solid var(--color-primary);
            color: var(--color-primary);
            height: .64rem;
            width: 1.5rem;
            text-align: center;
            line-height: .62rem;
            white-space: nowrap;
        }
        .isBtn:active{
            opacity: .7;
        }
    }
    .foot{
        margin: .7rem .82rem 0;
        .f-btn{
            height: 1.08rem;
            box-shadow: 0px 5px 10px 0px #C7EEEB;
            /deep/ span{
                font-size: .36rem;
                font-weight: normal;
            }
        }
    }
    
    
</style>